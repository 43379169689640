<template>
  <b-button
    :variant="variant"
    :size="size"
    :class="classes"
    @click="handleClick"
  >
    {{ label }}
  </b-button>
</template>
<script>
import {
  BButton,
} from 'bootstrap-vue'
import axios from '@axios'

export default {
  components: {
    BButton,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    href: {
      type: String,
      required: true,
    },
    xparam1: {
      type: [Boolean, Object, String, null],
      required: false,
      default: undefined,
    },
    xparam2: {
      type: [Boolean, Object, String, null],
      required: false,
      default: undefined,
    },
    xparam3: {
      type: [Boolean, Object, String, null],
      required: false,
      default: undefined,
    },
    xparam4: {
      type: [Boolean, Object, String, null],
      required: false,
      default: undefined,
    },
    xparam5: {
      type: [Boolean, Object, String, null],
      required: false,
      default: undefined,
    },
    xparam6: {
      type: [Boolean, Object, String, null],
      required: false,
      default: undefined,
    },
    zz2Period: {
      type: [Boolean, Object, String, null],
      required: false,
      default: undefined,
    },
    zz3periodFrom: {
      type: [Boolean, Object, String, null],
      required: false,
      default: undefined,
    },
    zz4periodTo: {
      type: [Boolean, Object, String, null],
      required: false,
      default: undefined,
    },
    zz6isPack: {
      type: [Boolean, Object, String, null],
      required: false,
      default: undefined,
    },
    q: {
      type: [Boolean, Object, String, null],
      required: false,
      default: undefined,
    },
    format: {
      type: String,
      required: true,
    },
    variant: {
      type: String,
      required: false,
      default: null,
    },
    size: {
      type: String,
      required: false,
      default: null,
    },
    classes: {
      type: String,
      required: false,
      default: null,
    },
  },
  setup(props) {
    const handleClick = () => {
      // eslint-disable-next-line prefer-destructuring
      let href = props.href
      // eslint-disable-next-line prefer-destructuring
      const xparam1 = props.xparam1
      // eslint-disable-next-line prefer-destructuring
      const xparam2 = props.xparam2
      // eslint-disable-next-line prefer-destructuring
      const xparam3 = props.xparam3
      // eslint-disable-next-line prefer-destructuring
      const xparam4 = props.xparam4
      // eslint-disable-next-line prefer-destructuring
      const xparam5 = props.xparam5
      // eslint-disable-next-line prefer-destructuring
      const xparam6 = props.xparam6
      // eslint-disable-next-line prefer-destructuring
      const zz2Period = props.zz2Period
      // eslint-disable-next-line prefer-destructuring
      const zz3periodFrom = props.zz3periodFrom
      // eslint-disable-next-line prefer-destructuring
      const zz4periodTo = props.zz4periodTo
      // eslint-disable-next-line prefer-destructuring
      const zz6isPack = props.zz6isPack
      // eslint-disable-next-line prefer-destructuring
      const q = props.q
      const params = new URLSearchParams(JSON.parse(JSON.stringify({
        xparam1,
        xparam2,
        xparam3,
        xparam4,
        xparam5,
        xparam6,
        zz2Period,
        zz3periodFrom,
        zz4periodTo,
        zz6isPack,
        q,
      }))).toString()
      if (href.indexOf('?') > -1) {
        // eslint-disable-next-line no-param-reassign
        href += `&${params}`
      } else {
        // eslint-disable-next-line no-param-reassign
        href += `?${params}`
      }
      axios({
        url: href,
        method: 'GET',
        responseType: 'blob',
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${Date.now()}.${props.format}`)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      })
    }

    return {
      handleClick,
    }
  },
}
</script>
